var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-tabs',{attrs:{"pills":"","card":"","vertical":""}},[_c('b-tab',{attrs:{"title":"Giới hạn đăng nhập theo IP","active":""},on:{"click":function($event){return _vm.onChangeTypeTab('ip')}}},[_c('b-card-text',[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{staticClass:"pl-0"},[_c('b-input',{attrs:{"size":"sm","placeholder":"Nhập địa chỉ ip"},on:{"keyup":function($event){return _vm.debounceFetchData()}},model:{value:(_vm.paramsFilter.ip),callback:function ($$v) {_vm.$set(_vm.paramsFilter, "ip", $$v)},expression:"paramsFilter.ip"}})],1),_c('b-col',[_c('b-form-select',{staticClass:"select-style",attrs:{"size":"sm","options":_vm.options,"value-field":"id","text-field":"name"},on:{"change":function($event){return _vm.debounceFetchData()}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("--Trạng thái--")])]},proxy:true}]),model:{value:(_vm.paramsFilter.status),callback:function ($$v) {_vm.$set(_vm.paramsFilter, "status", $$v)},expression:"paramsFilter.status"}})],1),_c('b-col',[_c('b-button',{staticClass:"font-weight-bolder",staticStyle:{"width":"70px"},attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.fetchData()}}},[_vm._v("Lọc ")])],1),_c('b-col',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
                name: 'create-user-access',
                params: {
                  type: 'ip',
                },
              }}},[_c('b-button',{staticClass:"font-weight-bolder",attrs:{"variant":"primary","size":"sm"}},[_c('i',{staticClass:"flaticon2-add-1",staticStyle:{"font-size":"1rem"}}),_vm._v(" Tạo mới ")])],1)],1)],1),_c('b-table',{attrs:{"items":_vm.whiteListIpDataTables,"fields":_vm.whiteListIpFieldTables,"busy":_vm.onLoading,"bordered":"","hover":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('vcl-table',{attrs:{"speed":5,"animate":true,"columns":10}})]},proxy:true},{key:"cell(index)",fn:function(row){return [_c('span',{staticClass:"justify-content-center"},[_vm._v(" "+_vm._s((row.index + 1) + ((_vm.currentPage - 1) * 10)))])]}},{key:"cell(status)",fn:function(row){return [_c('MLabel',{model:{value:(row.item.status),callback:function ($$v) {_vm.$set(row.item, "status", $$v)},expression:"row.item.status"}},[_vm._v(_vm._s(row.item.statusName)+" ")])]}},(_vm.checkPermissions(['USER_ACCESS_DELETE','USER_ACCESS_UPDATE']))?{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-dropdown',{attrs:{"size":"sm","id":"dropdown-left","no-caret":"","right":""}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"flaticon2-settings pr-0",staticStyle:{"font-size":"1rem"}})]),(_vm.checkPermission('USER_ACCESS_UPDATE'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.editData(row.item)}}},[_c('span',{staticStyle:{"color":"#3f4254"}},[_c('i',{staticClass:"flaticon2-pen mr-1",staticStyle:{"font-size":"1rem"}}),_vm._v(" Chỉnh sửa ")])]):_vm._e(),(_vm.checkPermission('USER_ACCESS_UPDATE'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.disableData(
                      row.item,
                      row.item.status === 1 ? 'lock' : 'unlock',
                    )}}},[(row.item.status === 1)?_c('span',{staticClass:"text-danger ont-weight-bolder",staticStyle:{"font-size":"12px"}},[_c('i',{staticClass:"fa-solid fa-lock text-danger mr-1",staticStyle:{"font-size":"1rem"}}),_vm._v(" Thu hồi ")]):_vm._e(),(row.item.status === 2)?_c('span',{staticClass:"text-success ont-weight-bolder",staticStyle:{"font-size":"12px"}},[_c('i',{staticClass:"fa-solid fa-lock-open text-success mr-1",staticStyle:{"font-size":"1rem"}}),_vm._v(" Hoạt động ")]):_vm._e()]):_vm._e(),(_vm.checkPermission('USER_ACCESS_DELETE'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.disableData(row.item, 'soft-delete')}}},[_c('span',{staticClass:"text-danger",staticStyle:{"font-size":"12px"}},[_c('i',{staticClass:"fa-solid fa-trash text-danger mr-1",staticStyle:{"font-size":"1rem"}}),_vm._v(" Xóa ")])]):_vm._e()],2)],1)]}}:null],null,true)}),_c('b-row',[_c('b-col',[_c('p',{staticClass:"mt-3 text-dark",staticStyle:{"font-weight":"500"}},[_vm._v(" Tổng số: "+_vm._s(_vm.totalItems)+" ")])]),_c('b-col',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-pagination-nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalItems > 10),expression:"totalItems > 10"}],attrs:{"pills":"","number-of-pages":_vm.numberOfPage,"link-gen":_vm.linkGen,"align":"right","use-router":""},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }