<template>
  <div class="white-list-ip">
    <KTCodePreview v-bind:title="pageTitle">
      <template v-slot:toolbar>
        <b-row> </b-row>
      </template>
      <template v-slot:preview>
        <span class="svg-icon svg-icon-lg mr-1 text-primary">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
        <strong>Cấu hình</strong> địa chỉ ip ứng dụng Dcore và Dpos
      </template>
    </KTCodePreview>

    <b-card no-body id="card-middle-content">
      <div>
        <b-tabs content-class="mt-3" v-model="tabIndex">
          <b-tab
            v-for="tab in Object.values(tabs)"
            :key="tab.id"
            @click="onChangeTab(tab.slug)"
          >
            <template #title>
              <i :class="tab.classIcon"></i>
              <strong :class="tab.classTextColor">{{ tab.name }}</strong>
            </template>
            <div>
              <keep-alive>
                <component v-bind:is="tab.component" />
              </keep-alive>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import TabOne from './TabOne.vue';
import TabTwo from './TabTwo.vue';
export default {
  data() {
    return {
      pageTitle: 'Danh sách truy cập',
      tabs: {
        ip: {
          id: 0,
          slug: 'ip',
          name: 'Giới hạn ip',
          classTextColor: 'text-danger',
          classIcon: 'fa-solid fa-lock text-danger mr-1',
          component: TabOne,
        },
        role: {
          id: 1,
          slug: 'role',
          name: 'Không giới hạn ip',
          classTextColor: 'text-success',
          classIcon: 'fa-solid fa-unlock text-success mr-1',
          component: TabTwo,
        },
      },
      type: 'ip',
      tabIndex: 0,
      defaultTab: 'ip',
    };
  },
  components: {
    KTCodePreview,
    TabOne,
    TabTwo,
  },
  created() {
    const tab = this.$route.query.tab || this.defaultTab;
    this.onChangeTab(tab);
  },
  mounted() {
    this.pageTitle = 'Danh sách cấu hình truy cập';
    this.$store.dispatch(SET_BREADCRUMB, [{ pageTitle: this.pageTitle }]);
  },
  methods: {
    onChangeTab(tab) {
      this.tabIndex = this.tabs[tab].id || 0;
      if (this.$route.query.tab !== tab) {
        this.$router
          .push({
            name: 'user-access',
            query: {
              tab: tab || this.defaultTab,
              page: 1
            },
          })
          .catch(() => {
            /* prevent Navigation cancelled from "/" to "/" with a new navigation */
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.white-list-ip ::v-deep {
  #card-middle-content {
    .table th,
    .table td {
      padding: 0.75rem;
      vertical-align: middle;
      border-top: 1px solid #ecf0f3;
    }

    .table thead tr > th {
      text-align: center;
    }

    padding: 10px;
    .card-header {
      padding: 1rem 1rem;
    }
    .card-body {
      padding: 1rem 1.25rem;
    }
    .col-auto {
      width: 30%;
      ul > li {
        border: 0.5px solid #ebedf0;
      }
    }
    .nav-pills .nav-link {
      border-radius: 0rem;
    }
    .nav.nav-pills .nav-link {
      color: #000000;
    }
    .nav.nav-pills .nav-link:hover {
      color: #3699ff;
    }
    .nav.nav-pills .show > .nav-link,
    .nav.nav-pills .nav-link.active {
      background-color: #f3f3f7 !important;
      color: #3699ff;
    }
  }
}
</style>
