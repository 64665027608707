<template>
  <div>
    <b-tabs pills card vertical v-model="tabIndex">
      <b-tab title="Cửa hàng" @click="onChangeTypeTab('store')"
        ><b-card-text>
          <b-row class="mb-4">
            <b-col class="pl-0">
              <b-input
                size="sm"
                placeholder="Nhập tên cửa hàng"
                v-model="paramsFilter.searchQuery"
                @keyup="debounceFetchData()"
              ></b-input>
            </b-col>
            <b-col>
                <b-form-select
                  class="select-style"
                  size="sm"
                  v-model="paramsFilter.status"
                  :options="options"
                    value-field="id"
                    text-field="name"
                  @change="debounceFetchData()"
                >
                  <template #first>
                    <b-form-select-option :value="null">--Trạng thái--</b-form-select-option>
                  </template>
                </b-form-select>
            </b-col>
            <b-col>
              <b-button
                style="width: 70px"
                variant="primary"
                size="sm"
                class="font-weight-bolder"
                @click="fetchData()"
                >Lọc
              </b-button>
            </b-col>
            <b-col class="text-right" v-if="checkPermission('USER_ACCESS_INSERT')">
              <router-link
                :to="{
                  name: 'create-user-access',
                  params: { type: 'store' },
                }"
              >
                <b-button
                  variant="primary"
                  size="sm"
                  class="font-weight-bolder"
                >
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                  Tạo mới
                </b-button>
              </router-link>
            </b-col>
          </b-row>
          <b-table
            :items="whiteListJobTitleAndEmployeeDataTables"
            :fields="whiteListJobTitleAndEmployeeFields"
            :busy="onLoading"
            bordered
            hover
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
            </template>
            <template v-slot:cell(index)="row">
              <span class="justify-content-center"> {{ (row.index + 1) + ((currentPage - 1) * 10) }}</span>
            </template>
            <template v-slot:cell(status)="row">
              <MLabel v-model="row.item.status"
                >{{ row.item.statusName }}
              </MLabel>
            </template>
            <template v-slot:cell(actions)="row" v-if="checkPermissions(['USER_ACCESS_UPDATE','USER_ACCESS_DELETE'])">
              <div class="d-flex justify-content-center">
                <b-dropdown size="sm" id="dropdown-left" no-caret right>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-settings pr-0"
                    ></i>
                  </template>
                  <b-dropdown-item @click="editData(row.item)" v-if="checkPermission('USER_ACCESS_UPDATE')">
                    <span style="color: #3f4254">
                      <i style="font-size: 1rem" class="flaticon2-pen mr-1"></i>
                      Chỉnh sửa
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="checkPermission('USER_ACCESS_UPDATE')"
                    @click="
                      disableData(
                        row.item,
                        row.item.status === 1 ? 'lock' : 'unlock',
                      )
                    "
                  >
                    <span
                      style="font-size: 12px"
                      v-if="row.item.status === 1"
                      class="text-danger ont-weight-bolder"
                    >
                      <i
                        style="font-size: 1rem"
                        class="fa-solid fa-lock text-danger mr-1"
                      ></i>
                      Thu hồi
                    </span>
                    <span
                      style="font-size: 12px"
                      v-if="row.item.status === 2"
                      class="text-success ont-weight-bolder"
                    >
                      <i
                        style="font-size: 1rem"
                        class="fa-solid fa-lock-open text-success mr-1"
                      ></i>
                      Hoạt động
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="checkPermission('USER_ACCESS_DELETE')"
                    @click="disableData(row.item, 'soft-delete')"
                  >
                    <span style="font-size: 12px" class="text-danger">
                      <i
                        style="font-size: 1rem"
                        class="fa-solid fa-trash text-danger mr-1"
                      ></i>
                      Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col>
              <p class="mt-3 text-dark" style="font-weight: 500">
                Tổng số:
                {{ totalItems }}
              </p>
            </b-col>
            <b-col>
              <b-col cols="12">
                <b-pagination-nav
                  pills
                  :number-of-pages="numberOfPage"
                  :link-gen="linkGen"
                  v-model="currentPage"
                  align="right"
                  use-router
                  @change="fetchData()"
                ></b-pagination-nav>
              </b-col>
            </b-col>
          </b-row>
        </b-card-text></b-tab
      >
      <b-tab title="Chức vụ" @click="onChangeTypeTab('jobTitle')"
        ><b-card-text>
          <b-row class="mb-4">
            <b-col>
              <b-col class="pl-0">
                <b-input
                  @keyup="debounceFetchData()"
                  size="sm"
                  placeholder="Nhập chức vụ"
                  v-model="paramsFilter.searchQuery"
                ></b-input>
              </b-col>
            </b-col>
            <b-col>
              <b-form-select
                class="select-style"
                size="sm"
                v-model="paramsFilter.status"
                :options="options"
                value-field="id"
                text-field="name"
                @change="debounceFetchData()"
              >
                <template #first>
                  <b-form-select-option :value="null">--Trạng thái--</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col>
              <b-button
                style="width: 70px"
                variant="primary"
                size="sm"
                class="font-weight-bolder"
                @click="fetchData()"
                >Lọc
              </b-button>
            </b-col>
            <b-col class="text-right" v-if="checkPermission('USER_ACCESS_INSERT')">
              <router-link
                :to="{
                  name: 'create-user-access',
                  params: { type: 'jobTitle' },
                }"
              >
                <b-button
                  variant="primary"
                  size="sm"
                  class="font-weight-bolder"
                >
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                  Tạo mới
                </b-button>
              </router-link>
            </b-col>
          </b-row>
          <b-table
            :items="whiteListJobTitleAndEmployeeDataTables"
            :fields="whiteListJobTitleAndEmployeeFields"
            :busy="onLoading"
            bordered
            hover
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
            </template>
            <template v-slot:cell(index)="row">
              <span class="justify-content-center"> {{ (row.index + 1) + ((currentPage - 1) * 10) }}</span>
            </template>
            <template v-slot:cell(status)="row">
              <MLabel v-model="row.item.status"
                >{{ row.item.statusName }}
              </MLabel>
            </template>
            <template v-slot:cell(actions)="row" v-if="checkPermissions(['USER_ACCESS_UPDATE','USER_ACCESS_DELETE'])">
              <div class="d-flex justify-content-center">
                <b-dropdown size="sm" id="dropdown-left" no-caret right>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-settings pr-0"
                    ></i>
                  </template>
                  <b-dropdown-item @click="editData(row.item)" v-if="checkPermission('USER_ACCESS_UPDATE')">
                    <span style="color: #3f4254">
                      <i style="font-size: 1rem" class="flaticon2-pen mr-1"></i>
                      Chỉnh sửa
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="checkPermission('USER_ACCESS_UPDATE')"
                    @click="
                      disableData(
                        row.item,
                        row.item.status === 1 ? 'lock' : 'unlock',
                      )
                    "
                  >
                    <span
                      style="font-size: 12px"
                      v-if="row.item.status === 1"
                      class="text-danger ont-weight-bolder"
                    >
                      <i
                        style="font-size: 1rem"
                        class="fa-solid fa-lock text-danger mr-1"
                      ></i>
                      Thu hồi
                    </span>
                    <span
                      style="font-size: 12px"
                      v-if="row.item.status === 2"
                      class="text-success ont-weight-bolder"
                    >
                      <i
                        style="font-size: 1rem"
                        class="fa-solid fa-lock-open text-success mr-1"
                      ></i>
                      Hoạt động
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="checkPermission('USER_ACCESS_DELETE')"
                    @click="disableData(row.item, 'soft-delete')"
                  >
                    <span style="font-size: 12px" class="text-danger">
                      <i
                        style="font-size: 1rem"
                        class="fa-solid fa-trash text-danger mr-1"
                      ></i>
                      Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col>
              <p class="mt-3 text-dark" style="font-weight: 500">
                Tổng số:
                {{ totalItems }}
              </p>
            </b-col>
            <b-col>
              <b-col cols="12">
                <b-pagination-nav
                  pills
                  :number-of-pages="numberOfPage"
                  :link-gen="linkGen"
                  v-model="currentPage"
                  align="right"
                  use-router
                  @change="fetchData()"
                ></b-pagination-nav>
              </b-col>
            </b-col>
          </b-row>
        </b-card-text></b-tab
      >
      <b-tab title="Nhân viên" @click="onChangeTypeTab('employee')"
        ><b-card-text>
          <b-row class="mb-4">
            <b-col>
              <b-col class="pl-0">
                <b-input
                  size="sm"
                  placeholder="Nhập tên nhân viên"
                  v-model="paramsFilter.searchQuery"
                  @keyup="debounceFetchData()"
                ></b-input>
              </b-col>
            </b-col>
            <b-col>
              <b-form-select
                class="select-style"
                size="sm"
                v-model="paramsFilter.status"
                :options="options"
                value-field="id"
                text-field="name"
                @change="debounceFetchData()"
              >
                <template #first>
                  <b-form-select-option :value="null">--Trạng thái--</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col>
              <b-button
                style="width: 70px"
                variant="primary"
                size="sm"
                class="font-weight-bolder"
                @click="fetchData()"
                >Lọc
              </b-button>
            </b-col>
            <b-col class="text-right" v-if="checkPermission('USER_ACCESS_INSERT')">
              <router-link
                :to="{
                  name: 'create-user-access',
                  params: { type: 'employee' },
                }"
              >
                <b-button
                  variant="primary"
                  size="sm"
                  class="font-weight-bolder"
                >
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                  Tạo mới
                </b-button>
              </router-link>
            </b-col>
          </b-row>
          <b-table
            :items="whiteListJobTitleAndEmployeeDataTables"
            :fields="whiteListJobTitleAndEmployeeFields"
            :busy="onLoading"
            bordered
            hover
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
            </template>
            <template v-slot:cell(index)="row">
              <span class="justify-content-center"> {{ (row.index + 1) + ((currentPage - 1) * 10) }}</span>
            </template>
            <template v-slot:cell(status)="row">
              <MLabel v-model="row.item.status"
                >{{ row.item.statusName }}
              </MLabel>
            </template>
            <template v-slot:cell(actions)="row" v-if="checkPermissions(['USER_ACCESS_UPDATE','USER_ACCESS_DELETE'])">
              <div class="d-flex justify-content-center">
                <b-dropdown size="sm" id="dropdown-left" no-caret right>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-settings pr-0"
                    ></i>
                  </template>
                  <b-dropdown-item @click="editData(row.item)" v-if="checkPermission('USER_ACCESS_UPDATE')">
                    <span style="color: #3f4254">
                      <i style="font-size: 1rem" class="flaticon2-pen mr-1"></i>
                      Chỉnh sửa
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="checkPermission('USER_ACCESS_UPDATE')"
                    @click="
                      disableData(
                        row.item,
                        row.item.status === 1 ? 'lock' : 'unlock',
                      )
                    "
                  >
                    <span
                      style="font-size: 12px"
                      v-if="row.item.status === 1"
                      class="text-danger ont-weight-bolder"
                    >
                      <i
                        style="font-size: 1rem"
                        class="fa-solid fa-lock text-danger mr-1"
                      ></i>
                      Thu hồi
                    </span>
                    <span
                      style="font-size: 12px"
                      v-if="row.item.status === 2"
                      class="text-success ont-weight-bolder"
                    >
                      <i
                        style="font-size: 1rem"
                        class="fa-solid fa-lock-open text-success mr-1"
                      ></i>
                      Hoạt động
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="checkPermission('USER_ACCESS_DELETE')"
                    @click="disableData(row.item, 'soft-delete')"
                  >
                    <span style="font-size: 12px" class="text-danger">
                      <i
                        style="font-size: 1rem"
                        class="fa-solid fa-trash text-danger mr-1"
                      ></i>
                      Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col>
              <p class="mt-3 text-dark" style="font-weight: 500">
                Tổng số:
                {{ totalItems }}
              </p>
            </b-col>
            <b-col>
              <b-col cols="12">
                <b-pagination-nav
                  pills
                  :number-of-pages="numberOfPage"
                  :link-gen="linkGen"
                  v-model="currentPage"
                  align="right"
                  use-router
                  @change="fetchData()"
                ></b-pagination-nav>
              </b-col>
            </b-col>
          </b-row>
        </b-card-text></b-tab
      >
    </b-tabs>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { convertPrice, makeToastSuccess, makeToastFaile } from '@/utils/common';
import { showConfirmDialog } from '@/utils/sweet-alert2';
import debounce from 'debounce';
import MLabel from '@/view/base/metronic/MLabel.vue';
import { TIME_TRIGGER } from '@/utils/constants';
import { USER_ACCESS_STATUS } from '@/utils/enum';
import { VclTable } from 'vue-content-loading';
import { checkPermission, checkPermissions } from '@/utils/saveDataToLocal';
export default {
  data() {
    return {
      pageTitle: '',
      totalItems: 0,
      numberOfPage: 1,
      currentPage: 1,
      linkGen: (pageNum) => {
        const queryParams = { ...this.$route.query };
        queryParams.page = pageNum;
        
        const queryString = Object.keys(queryParams)
          .map(key => `${key}=${queryParams[key]}`)
          .join('&');
        
        return queryParams.page === 1 ? `?${queryString}` : `?${queryString}`;
      },
      onLoading: false,
      whiteListJobTitleAndEmployeeDataTables: [],
      whiteListJobTitleAndEmployeeFields: [],
      paramsFilter: {
        ip: null,
        searchQuery: '',
        status: null,
      },
      defaultType: 'store',
      type: this.defaultType,
      tabIndex: 0,
      options:[
        {id: USER_ACCESS_STATUS.ACTIVE, name: 'Kích hoạt'},
        {id: USER_ACCESS_STATUS.DEACTIVE, name: 'Đã khoá',}
      ],
    };
  },
  components: {
    MLabel,
    VclTable,
  },
  // watch: {
  //   '$route.query': {
  //     handler(newValue) {
  //       if (newValue.tab) {
  //         this.$route.query.tab = newValue.tab;
  //         this.$route.query.status = '';
  //       }
  //       this.onChangeTypeTab(this.$route.query.type || this.defaultType);
  //     },
  //   },
  // },
  created() {
    this.onChangeTypeTab(this.$route.query.type || this.defaultType);
  },
  mounted() {
    this.pageTitle = 'Danh sách cấu hình truy cập';
    this.$store.dispatch(SET_BREADCRUMB, [{ pageTitle: this.pageTitle }]);
  },
  methods: {
    checkPermission,
    checkPermissions,
    showConfirmDialog,
    convertPrice,   
    debounce,
    onInitData() {
      switch (this.type) {
        case 'store': {
          this.whiteListJobTitleAndEmployeeFields = [
            {
              key: 'index',
              label: 'STT',
              sortable: false,
              thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
              tdClass: 'font-weight-bolder text-center',
            },
            {
              key: 'storeName',
              label: 'Tên cửa hàng',
              sortable: false,
              thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
            },
            {
              key: 'status',
              label: 'Trạng thái',
              sortable: false,
              thStyle: {
                fontWeight: 600,
                color: '#181c32',
                width: '10%',
                textAlign: 'center',
              },
              tdClass: 'text-center',
            },
            {
              key: 'actions',
              label: '',
              thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
            },
          ];

          break;
        }
        case 'jobTitle': {
          this.whiteListJobTitleAndEmployeeFields = [
            {
              key: 'index',
              label: 'STT',
              sortable: false,
              thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
              tdClass: 'font-weight-bolder text-center',
            },
            {
              key: 'jobTitleName',
              label: 'Chức danh',
              sortable: false,
              thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
            },
            {
              key: 'status',
              label: 'Trạng thái',
              sortable: false,
              thStyle: {
                fontWeight: 600,
                color: '#181c32',
                width: '10%',
                textAlign: 'center',
              },
              tdClass: 'text-center',
            },
            {
              key: 'actions',
              label: '',
              thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
            },
          ];

          break;
        }
        case 'employee': {
          this.whiteListJobTitleAndEmployeeFields = [
            {
              key: 'index',
              label: 'STT',
              sortable: false,
              thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
              tdClass: 'font-weight-bolder text-center',
            },
            {
              key: 'fullName',
              label: 'Tên nhân viên',
              sortable: false,
              thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
            },
            {
              key: 'status',
              label: 'Trạng thái',
              sortable: false,
              thStyle: {
                fontWeight: 600,
                color: '#181c32',
                width: '10%',
                textAlign: 'center',
              },
              tdClass: 'text-center',
            },
            {
              key: 'actions',
              label: '',
              thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
            },
          ];
          break;
        }
        default:
          break;
      }
    },
    fetchData() {
      this.onLoading = true;
      const PAGE_SIZE = 10;
      this.currentPage = this.$route.query.page || 1;
      const params = {
          size: PAGE_SIZE,
          page: this.paramsFilter?.searchQuery ? this.currentPage = 1 : this.currentPage,
          ...this.paramsFilter,
          type: this.type,
      }
      ApiService.query('user-accesses', {params})
      .then((response) => {
        const { data, total_row, total_page } = response.data.data;
        this.totalItems = total_row;
        this.numberOfPage = total_page || 1;
        this.whiteListJobTitleAndEmployeeDataTables = data;
        this.onLoading= false;
      }).catch(err=>{
        this.onLoading= false;
        const { message }  = err.response?.data || 'Lỗi'
        makeToastFaile( message )
      });
    },
    editData(item) {
      this.$router.push({
        name: 'update-user-access',
        params: { id: item.id, type: this.type },
      }).catch(() => {});
    },
    disableData(item, mode) {
      if (this.onLoading) {
        return;
      }
      const disableText = {
        lock: {
          name: 'Khoá',
        },
        unlock: {
          name: 'Mở khoá',
        },
        'soft-delete': {
          name: 'Xoá',
        },
      };

      this.showConfirmDialog(`Bạn có chắc muốn ${disableText[mode].name}`,
        `${disableText[mode].name} ứng dụng khônng thể hoàn tác!`)
        .then(async () => {
          try {
            this.onLoading = true;
            const result = await ApiService.patch(`/user-accesses/${item.id}/disable/`, {
              mode,
            })
            makeToastSuccess(result.data.message);
            this.fetchData();
            this.onLoading = false;
          } catch (error) {
            this.onLoading = false;
            if (error.response) {
              makeToastFaile(
                error.response.data ? error.response.data.message : 'Lỗi',
              );
            } else {
              makeToastFaile(error.message || 'Lỗi');
            }
          };
        }).catch(err => {
          this.onLoading = false;
          console.log(err)
        });
    },
    onChangeTypeTab(type) {
      this.paramsFilter={
        status: null,
        searchQuery: '',
      }
      // this.currentPage = 1;
      this.type = type;
      this.onInitData();

      switch (type) {
        case 'store': {
          this.tabIndex = 0;
          break;
        }
        case 'jobTitle': {
          this.tabIndex = 1;
          break;
        }
        case 'employee': {
          this.tabIndex = 2;
          break;
        }
        default:
          break;
      }
      if (this.$route.query.type !== type) {
        this.currentPage = 1;
        this.$route.query.page = this.currentPage;
        this.$router.push({
          name: 'user-access',
          query: {
            ...this.$route.query,
            page: this.currentPage,
          },
        }).catch(() => {});
      }
      this.fetchData();
    },
    debounceFetchData: debounce(function(){
      this.fetchData();
    },TIME_TRIGGER)
  },
};
</script>
<style lang="scss" scoped>
.white-list-ip ::v-deep {
  #card-middle-content {
    .table th,
    .table td {
      padding: 0.75rem;
      vertical-align: middle;
      border-top: 1px solid #ecf0f3;
    }

    .table thead tr > th {
      text-align: center;
    }

    padding: 10px;
    .card-header {
      padding: 1rem 1rem;
    }
    .card-body {
      padding: 1rem 1.25rem;
    }
    .col-auto {
      width: 30%;
      ul > li {
        border: 0.5px solid #ebedf0;
      }
    }
    .nav-pills .nav-link {
      border-radius: 0rem;
    }
    .nav.nav-pills .nav-link {
      color: #000000;
    }
    .nav.nav-pills .nav-link:hover {
      color: #3699ff;
    }
    .nav.nav-pills .show > .nav-link,
    .nav.nav-pills .nav-link.active {
      background-color: #f3f3f7 !important;
      color: #3699ff;
    }
  }
}
</style>
